// const strapiUrl = 'http://18.158.233.74:1337'
// const strapiUrl = 'http://localhost:1337'
// export const strapiUrl = 'http://18.193.22.222:1337'
export const strapiUrl = 'https://cms.savewithcarsharing.be/api'



const axios = require('axios')

export const getFormulas = () => axios.get(`${strapiUrl}/formules?filters[actief][$eq]=true&_limit=-1&_sort=naam:ASC&populate=*`)
export const getMotiefs = () => axios.get(`${strapiUrl}/motiefs?populate=*`)
export const getAanbieders = () => axios.get(`${strapiUrl}/aanbieders?populate=*`)
export const getAbos = () => axios.get(`${strapiUrl}/abonnements?filters[actief][$eq]=true&populate[formules][populate][0]=autos,kmprijzen,tijdstarieven,tijdsbeperkingen,afstandsbeperkingen,prepaid,abo&populate=aanbieder`)
export const getOwnerCars = () => axios.get(`${strapiUrl}/eigenwagens?populate=*`)
export const getAutos = () => axios.get(`${strapiUrl}/autos?populate=*`)
export const getAlgoritme = () => axios.get(`${strapiUrl}/algoritme?populate=*`)
export const getLabels = () => axios.get(`${strapiUrl}/labels?_limit=-1`)
export const getAfschrijving = () => axios.get(`${strapiUrl}/afschrijving?populate=*`)
export const getBrandstofs = () => axios.get(`${strapiUrl}/brandstofs?populate=*`)
export const getVersie = () => axios.get(`${strapiUrl}/versie?populate=*`)
export const getRegios = () => axios.get(`${strapiUrl}/regios?populate=*`)